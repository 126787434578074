// Here you can add other styles
@import './custom/message';
@import './custom/integracao';
@import './custom/table';

.button-table{
    margin-bottom: 1em;
    color: #ffffff;
    width: 100px;
    text-align: center;
    position: relative;
}

.btn-incluir{
    display: flex;
    font-weight: bold;
    border: 0;
    box-shadow: none;
}

.insert-button-message {
  background-color: #327EF0;
  padding: 8px;
  border: 0;
  box-shadow: none;

  &:hover {
    background-color: #2663bd !important;
  }
}

.logout{
    cursor: pointer;
}

.alert-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    text-align: center;
    padding: 10px;
    z-index: 1000; /* Garante que o alerta esteja acima de outros elementos na página */
    width: 100%;
  }

  .table-container {
    max-height: 600px;
    overflow-y: auto;
  }

  .ctable-sticky-header thead th {
    position: sticky;
    top: 0;
    color: white;
    z-index: 10;
  }


/* Estilo geral da scrollbar */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Fundo da scrollbar */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Barra de rolagem */
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 6px;
}

/* Autocomplete */
.autocomplete-results {
  position: absolute;
  background-color: #ffffff;
  width: 60%;
  z-index: 100;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: auto;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  list-style: none;
}

.autocomplete-results li {
  padding: 4px 10px;
  cursor: pointer;
}

.autocomplete-results li:hover {
  background-color: #f1f1f1;
}

.autocomplete-results li label {
  display: block;
  width: 100%;
}

.input-radio {
  margin-right: 5px;
}

.connector {
  display: flex;
  flex-direction: column;
  align-items: start;
  z-index: 1; /* Mantém a linha atrás das divs */
}

.horizontal-line {
  width: 100px; /* Comprimento da linha horizontal */
  height: 2px;
  background-color: black;
  margin-bottom: 0; /* Remove o espaço extra */
}

.vertical-line {
  width: 2px;
  height: 50px; /* Altura da linha vertical */
  background-color: black;
}

.param-pill {
  z-index: 2;
  height: 100%;
  width: 100%;
  & :hover {
    cursor: pointer;
  }
}

.card-container {
  text-align: center;
}

.param-drag {
  cursor: grab;
}

.param-dragging {
  cursor: grabbing;
}

.param-drop {
  cursor: pointer;
}

