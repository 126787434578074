.table-container {
  padding: 0.5em;
  position: relative;
}

.table-container .table-loading {
  filter: blur(2px);
}

.table-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.table-container .table-card ::hover {
  background-color: --bs-secondary-color;
}

.table-container .table-card > .row:last-child {
  border-bottom: none !important;
}
