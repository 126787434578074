//? Integracao.js
.message-list-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

//? IntegracaoCard.js
.integracao-card {
  width: 100%;
  margin-bottom: 2rem;
  background-color: #fafafa;
  padding: 1rem;

  border-radius: 1rem;

  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

  .btn-danger {
      background-color: #dc3545 !important;
      color: #fff !important;
      border-radius: 0.25rem !important;
      padding: 0.2rem 0.5rem !important;
  }

  .card-body {
    padding: 1rem;
    width: 100%;

    .row {
      width: 100%;
      padding: 1rem;
    }

    .card-info {
      width: 100%;
    }

    .card-info-header {
      display: table-header-group;

      th {
        font-size: 1rem;
        padding: 0.5rem 1rem;
        text-align: left;
        border-bottom: 1px solid #ddd;
      }
    }

    .card-info-body-integracao {
      display: table-row-group;

      td {
        font-size: 1rem;
        padding: 0.5rem 1rem;
        text-align: left;
        border-bottom: 1px solid #ddd;
      }
    }

    table {
      width: 100%;
      border-collapse: collapse;
    }
  }

  h2 {
    font-size: 1.2rem;
  }

  .card-body {
    padding: 1rem;
  }

  .card-title {
    h3 {
      font-size: 1rem;
    }
  }

  .card-text {
    font-size: 0.875rem;
  }
}

//? IntegracaoForm.js
.integracao-create-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    // gap: 20px;
    overflow-x: auto;
    padding-right: 10px;
    padding: 1em;

    .form-group {
      margin-bottom: 1rem;

      label {
        display: block;
        margin-bottom: 0.5rem;
        font-weight: 600;
        color: #555;
      }

      input,
      select,
      textarea {
        width: 100%;
        padding: 0.5rem;
        border: 1px solid #ddd;
        border-radius: 4px;
        font-size: 1rem;
        color: #333;
      }

      textarea {
        resize: vertical;
        min-height: 100px;
      }
    }

  //? btn remove field - form integracao
  .btn-remove-field {
    background-color: #dd4848 !important;
    color: #fff !important;
    border-radius: 0.25rem !important;
  }

  //? btn toggle field status - form integracao
  .btn-toggle-field-status-active {
    background-color: #dc3545 !important;
    color: #fff !important;
    border-radius: 0.25rem !important;
  }

  .btn-toggle-field-status-inactive {
    background-color: #007bff !important;
    color: #fff !important;
    border-radius: 0.25rem !important;
  }
}

//? ParamsFieldModel.js
.params-field-model-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;

  .close-button-params-field-model-button {
    background-color: transparent;
    border: none;
    font-size: 1.25rem;
    cursor: pointer;
    color: #999;

    &:hover {
      color: #333;
    }
  }

  .params-field-model {
    background-color: #fff;
    padding: 2rem;

    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

    max-width: 1200px;
    width: 100%;
    max-height: 80%;
    height: 100%;

    overflow-y: auto;

    position: relative;
    top: 30%;
    transform: translateY(-30%);

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.5rem;

      h2 {
        margin: 0;
        font-size: 1.5rem;
        color: #333;
      }

      button {
        background-color: transparent;
        border: none;
        font-size: 1.25rem;
        cursor: pointer;
        color: #999;

        &:hover {
          color: #333;
        }
      }
    }

    &-body {
      margin-bottom: 1.5rem;
    }

    &-footer {
      display: flex;
      justify-content: flex-end;

      button {
        padding: 0.5rem 1rem;
        font-size: 1rem;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        background-color: #007bff;
        color: #fff;

        &:hover {
          background-color: #0056b3;
        }

        &:not(:last-child) {
          margin-right: 0.5rem;
        }
      }
    }
  }
}

//? Btn Edit - Integracao
.edit-button-integracao {
  border-radius: 0.25rem;
  padding: 0.2rem 0.5rem;
  font-size: 0.875rem;
  font-weight: bold;

  margin-top: 2rem;

  .btn-editar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  span {
    margin-left: 0.5rem;
  }
}

//? Spinner - paramsFieldModel.js
.spinner-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

//? Modal
.params-field-model {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 1080px;
  width: 100%;
  max-height: 80%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.params-field-model-body {
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 1.5rem;
}

.params-field-model-footer {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0 0;
  border-top: 1px solid #ddd;
}

.integration-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  width: 100%;
}